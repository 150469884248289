const closeNotice = document.querySelector('#close-notice')
const closeAlert = document.querySelector('#close-alert')

closeAlert && closeAlert.addEventListener('click', (_event) => {
    const alert = document.querySelector('#alert');
    alert.remove();
});

closeNotice && closeNotice.addEventListener('click', (_event) => {
    const notice = document.querySelector('#notice');
    notice.remove();
});
